<template>
    <div class="login member-login">
        <div class="login-con">
            <div class="member-login-con">
                <Row>
                    <Col span="24">
                        <div class="login-content">
                            <div class="login-title">
                                <p class="login-title-1">栖智宝管理平台</p>
                                <p class="login-title-2"></p>
                            </div>
                            <Form ref="loginForm" :model="form" :rules="ruleValidate">
                                <FormItem prop="username">
                                    <Input v-model="form.username" placeholder="请输入手机号" class="member-login-input" />
                                </FormItem>
                                <FormItem prop="password">
                                    <Input type="password" v-model="form.password" placeholder="请输入密码" class="member-login-input" />
                                </FormItem>
                                <FormItem>
                                    <Button type="error" @click="handleSubmit" class="member-login-button">登录</Button>
                                </FormItem>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        <Verify
            @success="success"
            :mode="'pop'"
            :captchaType="'blockPuzzle'"
            :imgSize="{ width: '330px', height: '155px' }"
            ref="verify"
        ></Verify>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { setUserInfo, clearCache } from "../utils";
import Verify from "../components/common/verifition/Verify";

export default {
    name: "login",
    components: {
        Verify,
    },
    data() {
        return {
            form: {
                username: "",
                password: ""
            },
            ruleValidate: {
                username: [
                    {
                        required: true,
                        message: "账号不能为空",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "密码不能为空",
                        trigger: "blur",
                    },
                ],
            },
            institutionList: [],
        };
    },
    created() {},
    methods: {
        ...mapActions(["handleLogin", "getUserInfo"]),
        handleSubmit() {
            console.log('wefwf')
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.$refs.verify.show();
                }
            });
        },
        success(params) {
            this.$http
                .post("/api/login", this.form, { notLogin: true })
                .then((data) => {
                    clearCache();
                    setUserInfo(data);
                    this.$router.push({
                        name: this.$config.homeName,
                    });
                });
        },
        findPwdClick() {
            this.$router.push({
                name: "find",
            });
        }
    },
};
</script>
<style lang="less">
@import "../assets/less/login.less";
</style>
<style>
.member-login .login-con {
    width: auto;
    height: auto;
    min-width: 400px;
    transform: scaleX(1);
    box-shadow: -10px -10px 20px;
}
.member-login .login-con .member-login-con {
    border-radius: 7px;
    overflow: hidden;
    min-width: 300px;
}
.member-login .login-content {
    padding: 60px 40px 40px 40px;
    background: #fff;
    position: relative;
    flex-wrap: nowrap;
    white-space: nowrap;
    min-width: 100px;
}
.login-title {
    margin-bottom: 25%;
}
.login-title-1 {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    text-align: center;
}
.login-title-2 {
    font-family: PingFangSC-Medium;
    font-size: 24px;
    color: rgb(17, 131, 255);
    font-weight: bold;
}
.member-login .ivu-form-item {
    margin-top: 55px;
}
.member-login input.ivu-input,
.member-login input.ivu-input:focus,
.member-login input.ivu-input:hover {
    outline: none !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 2px solid #ccc !important;
    background: #fff !important;
    font-size: 14px;
}
.member-login-button {
    font-family: PingFangSC-Regular;
    border-radius: 18px;
    box-shadow: 0 4px 8px 0 rgba(1, 122, 255, 0.4);
    font-size: 14px;
    width: 100%;
    height: 36px;
}
.login-bg-con {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    z-index: -1;
    top: -60px;
    left: -82px;
}
.small-bg-con {
    background: rgba(1, 122, 255, 0.05);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 4px;
}
</style>