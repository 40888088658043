/**
 * 此处可直接引用自己项目封装好的 axios 配合后端联调
 */

import fetch from './../../../../api/fetch' // 组件内部封装的axios

// 获取验证图片  以及token
export function reqGet(data) {
    return fetch.post('/captcha/get', data, { notLogin: true, headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
}

// 滑动或者点选验证
export function reqCheck(data) {
    return fetch.post('/captcha/check', data, { notLogin: true, headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
}